<template>
  <el-dialog
    title="下载文件"
    :visible.sync="visible"
    :close-on-click-modal="false" 
    width="400px">
    <div class="download-type">
      <el-radio v-model="type" label="e">无保护</el-radio>
      <el-radio v-model="type" label="a" disabled>固定密码保护</el-radio>
      <el-input v-model="password" type="password" v-if="type == 'a'" placeholder="请输入保护密码"></el-input>
      <el-radio v-model="type" label="b" disabled>动态密码</el-radio>
      <el-radio v-model="type" label="ca" disabled>UKey保护</el-radio>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="doSubmit">下 载</el-button>
    </span>
  </el-dialog>
</template>

<script>

export default {
  components: {},
  // props: ['documentId'],
  data () {
    return {
      visible: false,
      type: "e",
      password: null,
      document: null
    }
  },
  computed: {},
  watch: {
    type(val){
      if(val != 'a'){
        this.password = null
      }
    }
  },
  methods: {
    show(doc){
      this.document = doc
      this.visible = true
    },
    doSubmit(){
      this.$store.dispatch("document/downLoadFile", {
        documentId: this.document,
        types: this.type,
        encryptPwd: this.password
      }).then(data=>{
          this.visible =false;
      })
    }
  },
  created () {

  },
  mounted () {

  },
}
</script>
<style lang='less' scoped>
.download-type{
  margin-top: -20px;

  &>*{
    display: block;
    margin: 10px 0;
  }

}
</style>