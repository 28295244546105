<template>
    <div>
        <el-dialog
            title="授权记录信息"
            :close-on-click-modal="false" 
            :visible.sync="dialogVisible">
            <div class="request-record-dialogs">
                <dl>
                    <dt>
                        <i class="el-icon-document"></i>
                    </dt>
                    <dd>
                        <div class="record-dialog-title">
                            <span>{{shareDetail.shareName}}</span>
                            <span :style="shareDetail.state|applyStateColor">状态：{{shareDetail.state|applyState}}</span>
                        </div>
                        <div>
                            <p>
                                <span>申请人：{{shareDetail.targetName}}</span>
                                <span>申请时间：{{shareDetail.createDate|date}}</span>
                                <!-- <span>联系电话：{{shareDetail.targetValue}}</span> -->
                                <span>申请原因：{{shareDetail.description}}</span>
                            </p>
                        </div>
                    </dd>
                </dl>
                <div class="apply-record-list">
                    <p>
                        <span>阅读范围</span>
                    </p>
                    <ul>
                        <li v-for="(item,index) in shareDetail.resources" :key="index" @click="render(item)">
                            <el-image :src="item | thumbIcon"></el-image>
                            <span>{{item.name}}</span>
                        </li>
                    </ul>
                </div>
                <div class="apply-record-list">
                    <p>
                        <span>共享目标</span>
                    </p>
                    <ul>
                        <li>
                            <span>{{shareDetail.targetName}}</span>
                        </li>
                    </ul>
                </div>
                <div class="apply-record-list" v-if="shareDetail.state == 1">
                    <p>
                        <span>操作授权</span>
                    </p>
                    <el-form ref="form" label-width="80px">
                        <el-form-item label="生效时间: ">

                              {{shareDetail['resources'][0].expireStartTime&&shareDetail['resources'][0].expireEndTime
                                    ? shareDetail['resources'][0].expireStartTime+'-'+shareDetail['resources'][0].expireEndTime : 
                                    shareDetail['resources'][0].expireEndTime
                                    ?'结束时间'+ shareDetail['resources'][0].expireEndTime: shareDetail['resources'][0].expireStartTime?shareDetail['resources'][0].expireStartTime+' - 永久':'永久'}}
                                    

                        </el-form-item>
                        <el-form-item label="可共享: ">
                            <el-switch  v-model="permissions.share" active-value="1" inactive-value="0" :disabled="true"></el-switch>
                        </el-form-item>
                        <el-form-item label="不可复制: ">
                            <el-switch  v-model="permissions.copy" active-value="1" inactive-value="0" :disabled="true"></el-switch>
                        </el-form-item>
                        <el-form-item label="可打印: ">
                            <el-switch v-model="permissions.print" active-value="1" inactive-value="0" :disabled="true"></el-switch>
                        </el-form-item>
                        <el-form-item label="可下载: ">
                            <el-switch v-model="permissions.download" active-value="1" inactive-value="0" :disabled="true"></el-switch>
                        </el-form-item>
                        <el-form-item label="添加水印: ">
                            <span>默认水印</span>
                        </el-form-item>
                        <el-form-item label="可读页数: ">
                            <span>{{permissions.readPageType==0 || permissions.readPageType == undefined? '全部' : permissions.readPage.split(',').length + "页"  }}</span>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="dialogVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="dialogVisible = false">确 定</el-button>
            </span>
        </el-dialog>

    </div>
</template>
<script>
export default {
    data(){
        return {
            dialogVisible: false,
            shareDetail:'',
            verker: 'shareDocList'
        }
    },
    computed: {
        permissions(){
            return this.shareDetail.permissions ? this.shareDetail.permissions.reduce((map, p) => {
                map[p.name] = p.value
                return map
            }, {}) : null
        },
    },
    methods: {
        onLoadPage(){
            this.shareDetail = this.$mapData.get('keepConferDetail')
            this.$store.commit('doc/createViewer', {
                viewerKey: this.verker,
                defaultFiles: this.shareDetail.resources
            })
        },
        // 跳转ofd页面
        render(docInfo){
            this.$ebus.$emit('publicReader',{
                shareId:this.shareDetail.shareId, 
                id:docInfo.documentId || docInfo.id,
                documentId: docInfo.documentId,
                suffix:docInfo.suffix
            })
        },
    }
}
</script>
<style lang="less">
.request-record-dialogs {
    dl {
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        dt {
            padding: 0;
            margin: 0;
            i {
                font-size: 32px;
                margin-right: 5px;
                color: #ffaf1d;
            }
        }
        dd {
            flex: 1;
            padding: 0;
            margin: 0;
            .record-dialog-title {
                display: flex;
                justify-content: space-between;
                margin-bottom: 5px;
                span:nth-child(1){
                    font-size: 16px;
                    font-weight: 600;
                    color: #009DD9;
                }
            }
            p {
                padding: 0;
                margin: 0;
                span {
                    margin-right: 15px;
                }
            }
        }
    }
    .apply-record-list {
        p {
            display: flex;
            margin: 20px 0 5px 0;
            span{
                position: relative;
                padding-left: 20px;
                font-size: 15px;
                &::before{
                    content: '';
                    // width: 3px;
                    // height: 18px;
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    background: #409eff;
                    position: absolute;
                    left: 0px;
                    top: 4px;
                }
            }
        }
        ul {
            margin: 0px;
            padding: 0px;
            li {
                list-style: none;
                height: 40px;
                line-height: 40px;
                border-bottom: 1px solid #f2f2f2;
                font-size: 14px;
                display: flex;
                align-items: center;
                cursor: pointer;
                .el-image {
                    margin-right: 5px;
                    width: 21px;
                    height: 21px;
                    img {
                        display: block;
                    }
                }
            }
            li:hover {
                color: #409eff;
            }
        }
        .el-form {
            .el-form-item {
                margin-bottom: 0px;
            }
        }
    }
}
</style>