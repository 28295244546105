<template>
  <div class="document-massage">
    <div class="document-massage-item" v-for="(item, index) in list" :key="index">
      <div class="document-massage-title" v-if="showTitle">
        <div>
          <span>{{ item.title }}</span>
        </div>
        <div>
          <!-- <router-link :to="{ path: item.path }">更多+</router-link> -->
          <el-button type="text" style="font-weight: 600;" @click="moreBtnJump(item)">更多+</el-button>
        </div>
      </div>
      <el-table
        size="small"
        :data="item.list"
        :show-header="false"
        width="400px"
      >
        <el-table-column label="标题" width="460">
          <template slot-scope="scope">
            <!-- 我的共享 -->
            <!-- <div
              v-if="scope.row.description == '共享'"
              class="title-link"
              @click="goDetail(scope.row)"
            >
              {{ scope.row.name ? scope.row.name : scope.row.shareName }}
            </div> -->

            <el-popover  v-if="scope.row.description == '共享'"
              placement="top" width='50px'
              trigger='hover'>
              <div style=" margin: 0">
                
                <!-- <el-button type="text" @click="shareDownload(scope.row)">下载</el-button> -->
                <el-button type="text" @click="goAuthManage(scope.row)">授权管理</el-button>
                <el-button type="text" @click="link(scope.row)">授权链</el-button>
                <el-button type="text" @click="enableMyShare(scope.row)">{{(scope.row.state===0 ?'取消共享':'启用共享')}}</el-button>
                <el-button type="text" @click="shareUrl(scope.row)">复制共享链接</el-button>
                <!-- <el-button type="text" icon="el-icon-share"></el-button> -->
              </div>
              <div  slot="reference" class="title-link" @click="goDetail(scope.row)">
                <i class="el-icon-view" style="color:red" ></i>
                {{scope.row.name?scope.row.name:scope.row.shareName}}
              </div>
            </el-popover> 

            <!-- 我的授权-->
            <el-popover v-else placement="top" trigger="hover">
              <div style="margin: 0" v-if="scope.row.state ==0">
                <el-button
                  type="text"
                  @click="changeType(scope.row, 1)"
                >通过</el-button>
                <el-button
                  type="text"
                  @click="changeType(scope.row, 2)"
                >拒绝</el-button>
              </div>
              <div style="margin: 0" v-else >已审核</div>
              <div
                slot="reference"
                class="title-link"
                @click="goDetail(scope.row)"
              >
                <i class="el-icon-success" v-if="scope.row.state==1"></i>
                <i class="el-icon-remove"  v-else-if="scope.row.state==3"></i>
                <i class="el-icon-warning-outline"   v-else-if="scope.row.state==0" ></i>
                <i class="el-icon-error" v-else></i>
                {{ scope.row.name ? scope.row.name : scope.row.shareName }}
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="时间" align="right">
          <template slot-scope="scope">
            <span class="time" slot="reference">{{
              scope.row.createDate | date("yyyy-MM-dd")
            }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <FileChain ref="chain" />
    <ShareDialogs ref="shareDialogs"></ShareDialogs>
    <RequestrecordDialog ref="requestRecordPages"></RequestrecordDialog>
    <ShareEdit ref="shareEditPage"></ShareEdit>
  </div>
</template>

<script>
import { requestAPI } from "liankong-ui-api";
import FileChain from '../files/FileChain.vue'
import ShareDialogs from '../liankong/components/ShareDialog.vue'
import RequestrecordDialog from '../liankong/components/RequestRecordDialog.vue'
import ShareEdit from '../liankong/components/ShareEditdialog.vue'
export default {
  data() {
    return {
      loading: false,
      visible: false,
    };
  },
   components: {
    FileChain,
    ShareDialogs,
    RequestrecordDialog,
    ShareEdit
  },
  props: {
    showTitle: {
      type: Boolean,
      default: true,
    },
    list: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  computed: {},
  methods: {
    moreBtnJump(item) {
      this.$store.commit('saveBreadList', [{
          path: item.path,
          title: item.title == '我的共享' ? '我的共享' : '授权记录'
      }])
      // 存储到vuex
      this.$store.commit('isActiveNameLeftMenu', '')
      this.$router.replace({
        path: item.path
      })
    },
    // 跳转详情
    goDetail(item) {
      // let queryId = {
      //   id: item.id,
      // };
      if (item.description == "共享") {
        // this.$router.replace({
        //   path: "shares/detail",
        //   query: queryId,
        // });
        this.$refs.shareDialogs.dialogVisible = true;

        this.$nextTick(()=>{
          this.$refs.shareDialogs.onLoadInit(item,true);
        })
      } else {
        this.$mapData.put("keepConferDetail", item);
        // this.$router.replace({
        //   path: "/confer/detail",
        // });
        this.$refs.requestRecordPages.dialogVisible = true;
        this.$nextTick(()=>{
           this.$refs.requestRecordPages.onLoadPage()
        })

      }
    },
    // 共享->取消共享
    enableMyShare(item){
      let clise = this.$loading({
        lock: true,
        text: '操作中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.$store.dispatch(`share/${item.state===0 ? 'disable':'enable'}`, item.id)
        .then(res => {
          if(res.code == 0){
            this.$message.success('操作成功')
          }else{
            this.$message.error(res.message);
          }
          clise.close()
        })
    },
    // 共享->授权管理
    goAuthManage(item){
      // this.$router.replace({
      //   path: "shares/edit",
      //   query: item.id,
      // });
        this.$refs.shareEditPage.dialogVisible = true;
        this.$nextTick(()=>{
          this.$refs.shareEditPage.onLoadEditInit(item)
        })
    },
    // 共享->授权连
    link(item){
      this.$refs['chain'].show('share',item)
    },

    // 共享->复制链接
    shareUrl(item) {
      this.$copyText(this.$options.filters["shareUrl"](item.id)).then(
        (e) => {
          this.$message({
            message: "复制成功,快去共享给好友吧",
            type: "success",
          });
        },
        (e) => {
          this.$message({
            message: "复制失败，请手动复制分析连接",
            type: "error",
          });
        }
      );
    },

    //我的授权
    changeType(item, state) {
      const loading = this.$loading({
        lock: true,
        text: "正在审核中请等待。。。",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      requestAPI
        .apply(item.id, {
          state,
        })
        .then(({ code, data, message }) => {
          if (code == 0) {
            loading.close();
            this.$message.success("审核成功");
            this.$store.dispatch("request/loadReceiveByMe", {
              reset: true,
              query: {
                type: ["1"],
              },
            });
            this.list=this.$store.state.request.views.receiver.list.slice(0, 5)||[];
          } else {
            loading.close();
            this.$message.error(message);
          }
        });
    },
  },
  created() {},
};
</script>
<style lang='less' scoped>
@import "../../styles/index";
.el-icon-success{
  color: green;
}.el-icon-remove{
  color:grey
}
.el-icon-error{
  color: red;
}
el-icon-remove

.document-massage {
  margin-left: -20px;
  margin-right: -20px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.document-massage-item {
  background: #fff;
}
.document-massage-title {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dcdcdc;
  color: @Brand;
  a {
    color: @Brand;
    text-decoration: none;
    font-size: @h4;
    font-weight: 900;
  }
  div {
    height: 50px;
    line-height: 50px;
    color: @Brand;
    font-size: @h2;
    color: #3f558c;
    span{
      font-weight: bold;
      color: #2c3e50;
      position: relative;
    }
    span:after{
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      background: red;
      border-radius: 2px;
      left: 0;
      top: 34px;
    }
  }
}
.title-link {
  position: relative;
  font-size: @h3;
  cursor: pointer;
}

.time {
  font-size: @h4;
}
/deep/ .el-table {
  .cell {
    white-space: nowrap;
    padding: 0px;
    a {
      text-decoration: none;
      color: #606266;
    }
    span {
      color: #999;
      .title-link {
        color: #606266;
      }
    }
  }
}
</style>