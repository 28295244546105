<template>
  <el-dialog :title="mode[showMode].title" :visible.sync="visible" :close-on-click-modal="false"   width="30%">
    <el-form ref="nameForm" :model="nameForm" :rules="rules" @submit.native.prevent>
      <el-form-item prop="name">
        <el-input v-model="nameForm.name" placeholder="请输入名称" name="username"  type="text" />
      </el-form-item>
      <!-- <el-form-item label="设为共享" v-if="!isOrgFolder && showMode == 'new'">
        <el-switch v-model="nameForm.share"></el-switch>
      </el-form-item> -->
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="visible = false">取 消</el-button>
      <el-button size="small" type="primary" @click="doSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  export default {
    components: {},
    props: ['viewerKey', 'datatype','renameId'],
    data() {
      return {
        visible: false,
        nameForm: {
          name: null,
          share: false,
        },
        showMode: "new",
        mode: {
          new: {
            title: "创建文件夹",
          },
          rename: {
            title: "重命名",
          },
        },
        rules: {
          name: [{required:true, message: "请输入名称" , trigger: 'blur'}],
        },
        orgParamsInfo: {}
      };
    },
    inject: ['editRefreshLeftMenu'],
    computed: {

      //是否是组织目录
      isOrgFolder() {
        return this.$store.getters["document/isOrgWorkSpace"];
      },
    },
    watch: {},
    methods: {
      show({ mode, value, params }) {
        this.showMode = mode;
        this.visible = true;           
        this.nameForm.name = value
        if(params) {
          this.orgParamsInfo = params
        }
      },
      /**
       * 提交表单
       */
      doSubmit() {
        this.$refs["nameForm"].validate((valid) => {
          if (valid) {
            if (this.showMode === 'new') {
              //新建文件夹
              this.loadingShow()
              if(this.viewerKey == 'org' || this.viewerKey == 'groupDoc') {
                  this.$store.dispatch('doc/createFolder', {
                    vkey: this.viewerKey,
                    name: this.nameForm.name,
                    orgParamsInfo: this.orgParamsInfo
                  }).then((res) => {
                    this.loadingHide()
                    this.visible = false
                    if(res.code == 1) {
                      this.$message.error(res.message)
                    }
                  }).catch((error) => {
                    this.$message.error(error.message)
                  })
              } else {
                this.$store.dispatch('doc/createFolder', {
                  vkey: this.viewerKey,
                  name: this.nameForm.name
                }).then((res) => {
                    this.loadingHide()
                    this.visible = false
                    if(res.code == 1) {
                      this.$message.error(res.message)
                    } else {
                      // 刷新左侧菜单
                      this.editRefreshLeftMenu();
                    }
                }).catch(() => {})
              }
             
            } else {
              this.$store
                .dispatch(`${this.datatype}/renameFolder`, {
                  id: this.renameId,
                  name: this.nameForm.name,
                  vkey: this.viewerKey,
                })
                .then((data) => {
                  if (data) {
                    
                    this.visible = false;
                  }
                }).catch(res=>{
                  this.visible = false;
                  this.$message.error(res)
                  
                })
            }
          }
        });
      },
    },
    created() {
    },
    mounted() {
    },
  };
</script>
<style lang='less' scoped>
</style>