<template>
  <div>
    <!-- 数据统计 -->
    <div class="countBox">
      <div class="main">
        <div class="box" @click="$router.push('/file/mydoc')">
          <div class="left-img"><img src="@/assets/sy-icon01.png" alt=""></div>
          <div class="right-count">
            <h2>我的文档</h2>
            <p>文档数：<span>{{ doc.doc }}</span> 个</p>
            <p style="height:17px;"> </p>
          </div>
        </div>
        <div class="box" @click="$router.push('/sharetion/sharedoc?active=second')">
          <div class="left-img" style="background:deeppink"><img src="@/assets/sy-icon02.png" alt=""></div>
          <div class="right-count">
            <h2>我的共享</h2>
            <p>共享数：<span>{{res.shareCount}}</span> 次</p>
            <p>文档数：<span>{{res.shareDocumentCount}}</span>个</p>
          </div>
        </div>
        <div class="box" @click="$router.push('/sharetion/authrecord?active=second')">
          <div class="left-img" style="background:red"><img src="@/assets/sy-icon03.png" alt=""></div>
          <div class="right-count">
            <h2>我的申请</h2>
            <p>总次数：<span>{{res.myRequestCount}}</span> 次</p>
            <p>待授权：<span>{{res.myRequestWaitCount}}</span> 次</p>
          </div>
        </div>
        <div class="box" @click="$router.push('/sharetion/authrecord?active=first')">
          <div class="left-img" style="background:green"><img src="@/assets/sy-icon04.png" alt=""></div>
          <div class="right-count">
            <h2>我的授权</h2>
            <p>待授权：<span>{{res.myAuthWaitCount}}</span> 个</p>
            <p>总授权：<span>{{ this.$store.state.request.views.receiver.list.length}}</span> 次</p>
          </div>
        </div>
        <!-- <div class="box" @click="$router.push('/doctool')">
          <div class="left-img" style="background:blue"><img src="@/assets/tool.png" alt=""></div>
          <div class="right-count">
            <h2>文档工具</h2>
            <p>可使用：<span>7</span> 个</p>
            <p style="height:17px;"> </p>
          </div>
        </div> -->
      </div>
    </div>

    <!-- 最新浏览 -->
    <div class="main" >
      <div class="list-title">最新浏览</div>
      <div class="look">
        <div class="lookbox" v-for="(item, index) in data" :key="index">
          <el-table  size="small" :data="item" :show-header="false" width="500px">
           <el-table-column label="标题" width="470">
              <template slot-scope="scope">
                <div class="title-link" @click="read(scope.row)"> 
                  <img :src="thumbIcon(scope.row)" alt="">
                  {{ scope.row.documentName?scope.row.documentName:scope.row.shareName }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="时间" align="right">
              <template slot-scope="scope">
                <span class="time" slot="reference">{{
                  scope.row.readDate | date("yyyy-MM-dd")
                }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>

    <!-- banner -->
    <div>
      <div class="banner-content">
        <img src="@/assets/banner_middle.png" alt="" />
      </div>
    </div>
    
    <!-- 共享和授权 -->
    <div class="main">
      <!-- 我的共享 -->
      <div class="main-box">
        <DocumentMassageList
          :list="data1"
          :showTitle="true"
        ></DocumentMassageList>

        <!-- 我的授权 -->
        <DocumentMassageList
          :list="data2"
          :showTitle="true"
        ></DocumentMassageList>
      </div>
    </div>

    <!-- 我的日志 -->
    <div class="myLog">
      <div class="main">
        <div class="list-title">最新日志</div>
        <div>
          <el-row v-for="(item, index) in logList" :key="index">
            <el-col :span="2">
              <div class="log-date">
                <!-- <p>{{ item.createDate | date("MM.dd") }}</p> -->
                <p class="log-date-year">
                  <!-- {{item.createDate}} -->
                  {{ item.createTime | date("yyyy-MM-dd") }}
                </p>
              </div>
            </el-col>
            <el-col :span="22">
              <div class="log-title">
                <!-- @click="goDetail(item)" -->
                {{ item.title }}
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>


    <FileDownloadDialog ref="fileDownloadDialog"></FileDownloadDialog>
    <FolderName ref="folderName" datatype="doc" :viewerKey="vkey"></FolderName>
  
  </div>
</template>

<script>
import DocumentMassageList from "@/views/index/list.vue";
import FileDownloadDialog from "@/views/files/Download.vue";
import FolderName from "@/views/files/FolderName.vue";
import { shareAPI,logsAPI } from 'liankong-ui-api' 


import iconDefault from '@/assets/img/icon-def.png'
import iconUnknown from '@/assets/img/icon-unknown.png'
import iconImage from '@/assets/img/icon-picture.png'
import iconOFD from '@/assets/img/icon-ofd.png'
import iconAudio from '@/assets/img/icon-audio.png'
import iconWord from '@/assets/img/icon-word.png'
import iconPpt from '@/assets/img/icon-ppt.png'
import iconPdf from '@/assets/img/icon-pdf.png'
import iconExcl from '@/assets/img/icon-excl.png'
import iconMP3 from '@/assets/doc-icon/mp3.png'
import iconMP4 from '@/assets/doc-icon/mp4.png'
import iconZIP from '@/assets/doc-icon/zip.png'

//后缀映射
const suffixIconCache = {
  def: iconDefault,
  unknown: iconUnknown,

  png: iconImage,
  jpg: iconImage,
  jpeg: iconImage,
  bmp: iconImage,

  ofd: iconOFD,

  doc: iconWord,
  DOC: iconWord,
  docx: iconWord,

  mp3: iconMP3,
  mp4: iconMP4,
  zip: iconZIP,
  ppt:iconPpt,
  pptx:iconPpt,
  pdf:iconPdf,
  xls:iconExcl,
  xlsx:iconExcl
}

export default {
  filters: {
    
  },
  components: {
    DocumentMassageList,
    FileDownloadDialog,
    FolderName,

  },
  data() {
    return {
      res: {},
      doc: {},
      vkey:'newest',
      logList:[]
    };
  },
  computed: { 
    data: function () {
      let list1 =
        this.$store.state.logs.views.read.list.slice(0, 5) || [];
      let list2 =
        this.$store.state.logs.views.read.list.slice(5, 10) || [];
      let arr = [];
      arr.push(list1);
      arr.push(list2);
      return arr;
    },
    data1: function () {
      let data = [
        {
          title: "我的共享",
          path: "/sharetion/sharedoc",
          list: this.$store.state.shares.views.sender.list.slice(0, 5) || [],
        },
      ];
      return data || [];
    },
    data2: function () {
      let data = [
        {
          title: "我的授权",
          path: "/sharetion/authrecord",
          list: this.$store.state.request.views.receiver.list.slice(0, 5) || [],
          // list: this.$store.state.request.views.receiver.list
        },
      ];
      return data || [];
    },
   
  },

  methods: {
     // 图标
    thumbIcon(doc){
      let iconKey = "unknown"
      if(suffixIconCache.hasOwnProperty(doc.suffix)){
        iconKey = doc.suffix
      }
      return suffixIconCache[iconKey] 
    },
    // 最新浏览->阅读
    read(item) {
      if(item.documentId !=null){
        item.id = item.documentId
      }
      this.$ebus.$emit('publicReader',{
          shareId:item.shareId, 
          id:item.documentId || item.id,
          documentId: item.documentId,
          name:item.name,
          suffix:item.suffix
      })
      
    },

    // 日志跳转详情页
    goDetail(item) {
      let queryId = {
        id: item.id,
      };
      this.$router.replace({
        path: "system/logs",
        query: queryId,
      });
    },
    // 获取共享列表
    getShareList(){
      this.$store.dispatch("shares/myShareList", {
        reset:true,
        query:{
          start:'',
          end:''
        }
      });
    },
    // 获取授权列表
    getAuthList(){
      this.$store.dispatch("request/loadReceiveByMe", {
        reset: true,
        query: {
          type: ["1"],
        },
      });
    }
    
  },
  created() {
    // 共享申请授权统计
    this.$store.dispatch("share/myCount").then((result) => {
      this.res = result.data;
    });
    
    //我的上传
    this.$store.dispatch("user/initLoad");
    this.doc = this.$store.state.user.counts;
    //浏览记录
    this.$store.dispatch("logs/readLog",{
      vkey:'read'
    })
    // 最新日志
    getloglist:{
      logsAPI.getLog({}).then(res=>{
        if(res.code==0){
          this.logList = res.data.slice(0,5)
        }
      })
    }
  },
  mounted() {
    this.getShareList()
    this.getAuthList()
  },
};
</script>

<style lang='less' scoped>
@import "../../styles/index";
.countBox {
  .main {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    .box {                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              
      width: 270px;
      height: 120px;
      background: #eee;
      border-radius: 6px;
      display: flex;
      justify-content: left;
      align-items: center;
      padding: 20px;
      box-sizing: border-box;
      .left-img {
        width: 72px;
        height: 72px;
        background: darkorange;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 12px;
        img {
          width: 48px;
        }
      }
      .right-count {
        h2 {
          margin: 0;
          font-size: 18px;
          color: #333;
        }
        p {
          margin: 0;
          font-size: 13px;
          margin-top: 5px;
          color: #666;
          span{
            font-weight: 400;
            color:#555
          }
        }
      }
    }
  }
}
.list-title {
  font-size: 22px;
  text-align: center;
  margin: 60px 0px;
  font-weight: bold;
  position: relative;
}
.list-title::after {
  position: absolute;
  content: "";
  display: inline-block;
  width: 60px;
  left: 50%;
  background: red;
  height: 2px;
  top: 34px;
  border-radius: 1px;
  margin-left: -30px;
}
.banner-content {
  text-align: center;
  margin: 20px 0;
  img {
    width: 100%;
    height: auto;
  }
}
.myLog {
  .log-title {
    height: 50px;
    line-height: 50px;
    box-sizing: border-box;
    border: 1px solid #efefef;
    border-left: 6px solid #60c2ff;
    color: #666;
    box-shadow: 0px 0px 4px#efefef;
    background: #fff;
    margin-bottom: 20px;
    padding-left: 30px;
  }
  .log-date {
    font-size: @h2;
    font-weight: bold;
    color: #586b9b;
    .log-date-year {
      color: #b9c3d7;
    }
  }
  .log-circle span {
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background: #60c2ff;
    margin-top: 31px;
  }
}
.main-box {
  justify-content: space-between;
  display: flex;
}

.title-link {
  position: relative;
  font-size: @h3;
  cursor: pointer;
  img{
    width: 24px;
    height: 24px;
    vertical-align: middle;
    margin-right: 5px;
  }
}

.time {
  font-size: @h4;
}
.look {
  display: flex;
  justify-content: space-between;
}
/deep/ .el-table .cell {
  padding: 0 0 0 0 !important;
}

.el-table .cell span {
  color: #999;
}
.lookmore {
  text-align: right;
  margin-top: 20px;
  a {
    color: #0084ff;
    text-decoration: none;
    font-size: 14px;
    font-weight: 900;
  }
}
.myLog {
  .log-circle {
    position: relative;
    box-sizing: border-box;
  }
  .log-circle:after {
    content: "";
    position: absolute;
    display: inline-block;
    height: 81px;
    width: 1px;
    left: 4.5px;
    top: 10px;
    background: #ccc;
  }
  .el-row:last-child {
    .log-circle::after {
      display: none;
    }
  }
}
</style>